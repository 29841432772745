import { Metadata } from '@redwoodjs/web'

const WorkflowsPublicViewPage = () => {
  return (
    <>
      <Metadata
        title="WorkflowsPublicView"
        description="WorkflowsPublicView page"
      />
    </>
  )
}

export default WorkflowsPublicViewPage
